import XAuth from "@xtreme-controls/xtreme-authentication";
import { getEnvString } from "../../envUtils";
import { version } from "../../../package.json";

export default {
    namespaced: true,
    state: () => ({
        xAuth: undefined,
        isLocalMode: getEnvString("$VUE_APP_LOCALMODE", "false") !== "false",
    }),
    mutations: {
        updateXAuth(state, xAuth) {
            state.xAuth = xAuth;
        },

        connected(state, connected) {
            state.connected = connected;
        },

        disconnected(state) {
            state.connected = false;
        },

        refreshInterval(state, interval) {
            clearInterval(state.refreshInterval);
            state.refreshInterval = undefined;
            if (interval) {
                state.refreshInterval = interval;
            }
        },

        setLocalMode(state, mode) {
            state.isLocalMode = mode;
        },
    },
    actions: {
        async login({ state }, loginPacket) {
            if (state.xAuth.loggedIn()) {
                return state.xAuth.user;
            }
            return state.xAuth.login(loginPacket.username, loginPacket.password);
        },

        async logout({ state }) {
            localStorage.clear();
            await state.xAuth.logout();
        },

        async send({ state, rootGetters, dispatch }, msg) {
            const message = msg;
            if (!state.xAuth) {
                dispatch("initialise");
                await new Promise((resolve) => {
                    setTimeout(() => resolve(), 750);
                });
            }

            if (message) {
                message.meta = {
                    ...message.meta,
                    version,
                    roomName: rootGetters.getStaticRoom,
                    location: !message.meta?.location ? localStorage.getItem("location") : message.meta.location,
                };
            }

            return state.xAuth.send(message);
        },

        async connectionUpdate({ state, commit, dispatch }, status) {
            commit("connected", status);
            if (!status && !state.refreshInterval) {
                const interval = setInterval(async () => {
                    console.info("reinitialisig @", Date.now());
                    commit("updateXAuth", undefined);
                    dispatch("initialise");
                }, 1000);
                commit("refreshInterval", interval);
            } else if (status && state.refreshInterval) {
                commit("refreshInterval");
            }
        },

        initialise({ state, commit, dispatch }) {
            if (!state.xAuth) {
                try {
                    const { hostname } = window.location;
                    const wsPort = getEnvString("$VUE_APP_WS_PORT", "");

                    // Adds a port for testing on localhost
                    let wsHost = `${hostname}/ws/app/`;
                    if (wsPort) {
                        wsHost = `${hostname}:${wsPort}/ws/app/`;
                    }

                    const xAuthInstance = new XAuth(wsHost, { requireSmsVerification: true, localmode: state.isLocalMode }, (msg) => {
                        dispatch("receivedFeedback", msg, { root: true });
                    });

                    xAuthInstance.on("logged-out", () => {
                        dispatch("logout");
                        commit("disconnected");
                    });

                    xAuthInstance.on("connected", (connected) => {
                        console.info("websocket connection status", connected, "@", Date.now());
                        dispatch("connectionUpdate", connected);
                        dispatch("feedbackInit", { root: true });
                    });

                    if (state.isLocalMode) {
                        console.info("attempting to login localmode");
                        xAuthInstance.login();
                    }

                    commit("updateXAuth", xAuthInstance);
                } catch (err) {
                    console.error("xAuth Connection Error", err);
                }
            }
        },
        clearLocalMode({ state, commit, dispatch }) {
            if (state.isLocalMode && state.xAuth) {
                dispatch("logout");
            }

            commit("setLocalMode", false);
        },
    },
};
