<template>
    <div :class="absolute ? 'absolute-timer' : ''" :style="{'background-color': bgColour}">
        <v-card
            width="100%"
            height="100%"
            elevation="0"
            color="transparent"
        >
            <div class="countdown flex-d justify-center align-center" :class="absolute ? '' : 'flex-column-reverse' ">
                <div class="flex-d justify-center align-center">
                    <h3 v-if="showCountdown" class="t-12p">{{ headerText }}</h3>
                    <div v-if="showCountdown" :class="`timer ${timeLeft}`" :style="{'color': countdownFontColour, 'font-size': countdownFontSize}">
                        {{ String(countdown.hours).padStart(1, '0') }}:{{ String(countdown.minutes).padStart(2, '0') }}:{{ String(countdown.seconds).padStart(2, '0') }}
                    </div>
                </div>
                <div class="flex-d justify-center align-center">
                    <h3 v-if="showTarget" data-test="target-header">{{ headerText }}</h3>
                    <div v-if="showTarget" :class="`timer pa-2`" :style="{'color': timerFontColour, 'font-size': timerFontSize}">
                        {{ time }}
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>
<script>
export default {
    props: {
        target: {
            type: Object,
            default: () => ({
                hours: 0,
                minutes: 0,
                seconds: 0,
                epochTarget: 0,
            }),
        },
        set: {
            type: Boolean,
            default: false,
        },
        absolute: {
            type: Boolean,
            default: false,
        },
        showTarget: {
            type: Boolean,
            default: true,
        },
        showCountdown: {
            type: Boolean,
            default: true,
        },
        countdownFontColour: {
            type: String,
            default: "rgb(0, 0, 0)",
        },
        timerFontColour: {
            type: String,
            default: "rgb(0, 0, 0)",
        },
        bgColour: {
            type: String,
            default: "rgb(255,255,255)",
        },
        countdownFontSize: {
            type: String,
            default: "300px",
        },
        timerFontSize: {
            type: String,
            default: "66px",
        },
        headerText: {
            type: String,
            default: "Shutdown Time",
        },
    },
    data() {
        return {
            date: new Date(),
            timerInterval: undefined,
        };
    },
    computed: {
        timeLeft() {
            if (!this.set) {
                return "time-left-not-set";
            }

            const { hours, minutes } = this.countdown;

            if (hours === 0) {
                if (minutes < 1) {
                    return "time-left-red-flash";
                }
                if (minutes < 5) {
                    return "time-left-red";
                }
                if (minutes < 10) {
                    return "time-left-orange";
                }
            }

            return "time-left-normal";
        },

        time() {
            let { hours } = this.target;
            const minutes = String(this.target.minutes).padStart(2, "0");
            hours %= 12;
            hours = hours || 12;

            return `${hours}:${minutes}`;
        },

        countdown() {
            const difference = this.target.target - this.date;
            return this.createTimeObject(difference);
        },
    },
    mounted() {
        this.updateTime();
        this.timerInterval = setInterval(this.updateTime, 1000);
    },

    methods: {
        updateTime() {
            if (this.target.target - this.date > 1000) {
                this.date = new Date();
            }
        },

        createTimeObject(time) {
            const timeObj = {};
            const secondsTotal = Math.max(0, Math.floor(time / 1000));

            timeObj.hours = Math.floor((secondsTotal % (3600 * 24)) / 3600);
            timeObj.minutes = Math.floor((secondsTotal % 3600) / 60);
            timeObj.seconds = secondsTotal % 60;

            return timeObj;
        },
    },
};
</script>
<style>
@keyframes flashAnimation {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
.absolute-timer {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
}

.countdown {
    position: relative;
    height: 100%;
    margin: auto;
    text-align: center;
}

.timer {
    font-family: 'Minim', sans-serif;
    color: black;
}

.t-12p {
    margin-top: 12% !important;
}

.time-left-not-set{
    justify-content: center;
    transition: color 0.5s ease;
    color: lightgray;
}

.time-left-normal {
    transition: color 0.5s ease;
    color: black;
}

.time-left-red {
    transition: color 30s ease;
    color: red !important;
}
.time-left-orange {
    transition: color 30s ease;
    color: orange !important;
}

.time-left-red-flash {
    animation: flashAnimation 1s infinite;
    color: red !important;
}
</style>
